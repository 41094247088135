import { DrawFocus, SafeHtml } from '@thg-commerce/gravity-elements'
import { css, styled } from '@thg-commerce/gravity-patterns/theme'
import { keyframes, mq, spacing, Text } from '@thg-commerce/gravity-theme'

import { CombinedThemeInterface } from '../..'

const StyledText = css`
  ${(props) =>
    Text(
      props.theme.widgets.uspBar.font.entry,
      props.theme.widgets.uspBar.font.style,
    )};
  text-transform: ${(props) => props.theme.widgets.uspBar.font.transform};
  text-decoration: ${(props) => props.theme.widgets.uspBar.font.textDecoration};
  color: ${(props) => props.theme.widgets.uspBar.font.textColor};

  &:hover {
    ${(props) =>
      Text(
        props.theme.widgets.uspBar.hoverFont.entry,
        props.theme.widgets.uspBar.hoverFont.style,
      )};
    text-transform: ${(props) =>
      props.theme.widgets.uspBar.hoverFont.transform};
    text-decoration: ${(props) =>
      props.theme.widgets.uspBar.hoverFont.textDecoration};
    color: ${(props) => props.theme.widgets.uspBar.hoverFont.textColor};
  }
`

const visibility = (panelCount: number) => keyframes`
  0% { visibility: visible }
  ${100 / panelCount}% { visibility: hidden }
`

export const Panel = styled.div<{ panelCount: number; index: number }>`
  width: 100%;
  justify-content: center;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${(props) => props.panelCount * 2}s
    ${(props) => visibility(props.panelCount)} infinite;
  animation-delay: ${(props) => props.index * 2}s;

  &:first-child {
    position: static;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    display: flex;
    padding: 0 ${spacing(2)};
    width: auto;
    animation: none;
    visibility: visible;
    position: static;
  }
`

const dotHighlight = (
  theme: CombinedThemeInterface,
  panelCount: number,
) => keyframes`
  0% { background: ${theme.colors.palette.greys.darker} }
  ${100 / panelCount - 0.01}% { background: ${
  theme.colors.palette.greys.darker
} }
  ${100 / panelCount}% { background: ${theme.colors.palette.greys.light} }
  100% { background: ${theme.colors.palette.greys.light} }
`

export const IndicatorContainer = styled.div`
  display: flex;
  margin: -6px 0 1px 0;

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    display: none;
  }
`

export const IndicatorDot = styled.span<{ panelCount: number; index: number }>`
  width: 5px;
  height: 5px;
  margin: 0 2px;
  border-radius: 3px;
  background: ${(props) => props.theme.colors.palette.greys.light};
  animation: ${(props) => props.panelCount * 2}s
    ${(props) => dotHighlight(props.theme, props.panelCount)} infinite;
  animation-delay: ${(props) => props.index * 2}s;
`

export const EmptyPanel = styled.div`
  height: ${spacing(7)};
`

export const StyledHref = styled.a`
  ${StyledText}
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding-right: 2px;
  ${DrawFocus()};
  text-align: center;
  color: ${(props) => props.theme.widgets.uspBar.font.textColor};

  &::after {
    ${StyledText}
    display: block;
    content: 'P';
    height: 0;
    visibility: hidden;
  }

  &:hover {
    ${(props) =>
      Text(
        props.theme.widgets.uspBar.hoverFont.entry,
        props.theme.widgets.uspBar.hoverFont.style,
      )};
    text-transform: ${(props) =>
      props.theme.widgets.uspBar.hoverFont.transform};
    text-decoration: ${(props) =>
      props.theme.widgets.uspBar.hoverFont.textDecoration};
    color: ${(props) => props.theme.widgets.uspBar.hoverFont.textColor};
  }

  &:focus-visible {
    border-color: ${(props) =>
      props.theme.widgets.uspBar.focusBorderColor ||
      props.theme.colors.palette.brand.base};
  }
`

export const StyledSVGWrapper = styled.div<{
  useStrokeFill?: boolean
  isClipBoard?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  ${(props) =>
    props.isClipBoard
      ? `svg {
    fill: ${props.theme.widgets.uspBar.iconFill};
    path {
      stroke: ${props.theme.widgets.uspBar.iconFill};
    }
  }`
      : `svg {
  
    path {
        ${
          props.useStrokeFill
            ? `stroke: ${props.theme.widgets.uspBar.iconFill};`
            : `fill: ${props.theme.widgets.uspBar.iconFill};`
        }
        
    }
  `}
`

export const NoLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  ${StyledText};
  text-align: center;

  &::after {
    ${StyledText}
    display: block;
    content: 'P';
    height: 0;
    visibility: hidden;
  }
`
export const USPBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  flex-direction: column;
  ${(props) => mq(props.theme.breakpointUtils.map, 'xs')} {
    padding: ${spacing(1)};
  }
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    padding: ${(props) => spacing(props.theme.widgets.uspBar.padding)};
  }
`

export const USPBarContainer = styled.div<{
  backgroundColour?: string
}>`
  background-color: ${(props) =>
    props.backgroundColour ?? props.theme.widgets.uspBar.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 100%;

  ${(props) => mq(props.theme.breakpointUtils.map, 'xs')} {
    ${(props) =>
      props.theme.widgets.uspBar.height &&
      `height: ${props.theme.widgets.uspBar.height}`}
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    height: auto;
  }
`

const countdownWrapperDesktopStyles = css`
  ${Text('medium1', 'alternate')};
  vertical-align: middle;

  &::after {
    ${Text('medium1', 'alternate')}
    display: block;
    content: '00:00:00';
    font-weight: 700;
    height: 0;
    visibility: hidden;
  }
`

export const CountdownWrapper = styled.div<{ isDesktop }>`
  ${Text('small', 'alternate')};
  padding-left: ${spacing(1)};

  ${(props) => props.isDesktop && countdownWrapperDesktopStyles}
`

export const CountdownTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
`

export const StyledSafeHtml = styled(SafeHtml)`
  ${StyledText}

  * {
    ${StyledText}
  }
`
