import React, { useCallback, useEffect, useMemo, useState } from 'react'
import loadable from '@loadable/component'

import { CombinedThemeInterface } from '@thg-commerce/gravity-patterns'
import { withPrefetch } from '@thg-commerce/gravity-system/prefetch'

import {
  CountdownTextWrapper,
  CountdownWrapper,
  IndicatorContainer,
  IndicatorDot,
  NoLinkContainer,
  Panel,
  StyledHref,
  StyledSafeHtml,
  StyledSVGWrapper,
  USPBarWrapper,
} from '../styles'
import type { AlternateIcons, USPBarPanel, USPBarQueryResult } from '../types'

const Time = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Time'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Coins = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/RewardPoints'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const InternationalDelivery = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/InternationalDelivery'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Checkmark = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Checkmark'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Trustpilot = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Trustpilot'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Calender = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Calender'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const ContentEmail = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ContentEmail'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Truck = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Truck'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Smartphone = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Smartphone'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const TelephoneUsp = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/TelephoneUsp'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Clipboard = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Clipboard'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const ClickAndCollect = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ClickAndCollect'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const StoreLocator = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/StoreLocator'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const ZipGrey = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ZipGrey'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const AccountReturns = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/AccountReturns'),
)

const PrefetchStyledHref = withPrefetch('href', StyledHref)

export const timingPollResolution = 200

const formatNumber = (number: number) => {
  return `0${number}`.slice(-2)
}

export const numberToDay = (dayAsNumber: number): string => {
  switch (dayAsNumber) {
    case 0:
      return 'sunday'
    case 1:
      return 'monday'
    case 2:
      return 'tuesday'
    case 3:
      return 'wednesday'
    case 4:
      return 'thursday'
    case 5:
      return 'friday'
    case 6:
      return 'saturday'
    default:
      return 'thursday'
  }
}

const CountDownText = (props: {
  rawPanelInformation: USPBarQueryResult
  isDesktop: boolean
}) => {
  const { rawPanelInformation, isDesktop } = props
  const { timezoneOffset } = rawPanelInformation

  const [workingDay, setDay] = useState(new Date().getDay())
  const [countdownTarget, updateCountdownTarget] = useState(
    rawPanelInformation[`${numberToDay(workingDay)}Cutoff`],
  )
  const [secondWatcher, updateSeconds] = useState((Date.now() * 1000) % 60)

  useEffect(() => {
    const tickFrame = setInterval(tick, timingPollResolution / 5)

    return () => {
      clearInterval(tickFrame)
    }
  })

  const tick = useCallback(() => {
    const currentDay = new Date().getDay()
    const dayChanged = currentDay !== workingDay

    if (dayChanged) {
      setDay(currentDay)
      updateCountdownTarget(
        rawPanelInformation[`${numberToDay(workingDay)}Cutoff`],
      )
    }

    const currentSeconds = (Date.now() * 1000) % 60
    if (secondWatcher !== currentSeconds) updateSeconds(currentSeconds)
  }, [secondWatcher, rawPanelInformation, workingDay])

  const finishedCountdown = useMemo(() => {
    return (
      <CountdownTextWrapper
        data-testid="countdown-finished"
        key="countdown-text-empty"
      >
        {rawPanelInformation.countDownText}
      </CountdownTextWrapper>
    )
  }, [rawPanelInformation])

  const timeRegex = /^(\d|\d\d):(\d\d)$/
  const hoursAndMinutes = timeRegex.exec(countdownTarget)

  if (!hoursAndMinutes) {
    return finishedCountdown
  }

  const targetHour =
    parseInt(hoursAndMinutes[1], 10) - parseInt(timezoneOffset, 10)
  const targetMinute = parseInt(hoursAndMinutes[2], 10)

  if (
    isNaN(targetHour) ||
    isNaN(targetMinute) ||
    targetHour < 0 ||
    targetHour > 23 ||
    targetMinute < 0 ||
    targetMinute > 59
  ) {
    return finishedCountdown
  }

  const currentTime = new Date()
  const targetTime = new Date()
  const timeToCountdown =
    targetTime.setHours(targetHour, targetMinute, 0) - currentTime.getTime()

  if (timeToCountdown < 0) {
    return finishedCountdown
  }

  const hours = formatNumber(Math.floor((timeToCountdown / 3600 / 1000) % 24))
  const minutes = formatNumber(Math.floor((timeToCountdown / 60 / 1000) % 60))
  const seconds = formatNumber(Math.floor((timeToCountdown / 1000) % 60))

  return (
    <CountdownTextWrapper
      data-testid={`countdown-${hours}:${minutes}:${seconds}`}
      key="countdown-text"
    >
      {rawPanelInformation.countDownText}:
      <CountdownWrapper key="wrapper" isDesktop={isDesktop}>
        {hours}:{minutes}:{seconds}
      </CountdownWrapper>
    </CountdownTextWrapper>
  )
}
const renderSvgIcon = (svgPath: string, theme: CombinedThemeInterface) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox={theme.widgets?.uspBar?.icon?.viewBox || '0 0 24 24'}
      width={theme.widgets?.uspBar?.icon?.width || 24}
      height={theme.widgets?.uspBar?.icon?.height || 24}
    >
      <path d={svgPath} fillRule="evenodd" />
    </SvgIcon>
  )
}
const getIconComponent = (
  iconType: string,
  rawPanelInformation: USPBarQueryResult,
) => {
  const useAlternateIcon = rawPanelInformation.useAlternateIcon || {}

  const standardIcons = {
    trustpilot: Trustpilot,
    rewards: Coins,
    countDown: Time,
    internationalDelivery: InternationalDelivery,
    checkmark: Checkmark,
    calender: Calender,
    email: ContentEmail,
    delivery: Truck,
    clickAndCollect: ClickAndCollect,
    returns: AccountReturns,
  }

  if (iconType === 'call') {
    return rawPanelInformation.callIcon === 'mobile' ? Smartphone : TelephoneUsp
  }

  if (iconType === 'quality') {
    return useAlternateIcon.qualityText ? ZipGrey : Clipboard
  }

  if (iconType === 'store') {
    return useAlternateIcon.storeText ? StoreLocator : Clipboard
  }

  return standardIcons[iconType] || Clipboard
}

const getSvgIcon = (
  iconType: string,
  theme: CombinedThemeInterface,
  rawPanelInformation: USPBarQueryResult,
) => {
  const iconConfig = theme.widgets?.uspBar?.icon?.[iconType] || {}
  const defaultFill =
    theme.widgets?.uspBar?.iconFill ||
    theme.colors?.palette?.greys?.dark ||
    '#000'

  if (iconConfig.svgPath) {
    return renderSvgIcon(iconConfig.svgPath, theme)
  }

  const IconComponent = getIconComponent(iconType, rawPanelInformation)
  return (
    <IconComponent fill={defaultFill} aria-hidden="true" focusable="false" />
  )
}
const createPanelConfig = (
  dataField: string,
  iconType: string,
  rawPanelInformation: USPBarQueryResult,
  theme: CombinedThemeInterface,
  isDesktop: boolean,
) => {
  const childComponent =
    iconType === 'countDown' ? (
      <CountDownText
        rawPanelInformation={rawPanelInformation}
        isDesktop={isDesktop}
      />
    ) : (
      <StyledSafeHtml
        data-testid={`${iconType}-text`}
        content={rawPanelInformation[dataField]}
        removeTagsAndContent={false}
        tagsToRemove={[]}
      />
    )

  const specialLinkNames = {
    trustpilot: 'trustPilotLink',
    rewards: 'rewardPointsLink',
  }

  const linkPropertyName = specialLinkNames[iconType] || `${iconType}Link`

  return {
    child: childComponent,
    link: rawPanelInformation[linkPropertyName],
    svg: getSvgIcon(iconType, theme, rawPanelInformation),
    key: dataField,
    order: theme.widgets.uspBar.icon[iconType].order,
    useStrokeFill: ['trustpilot', 'checkmark', 'countDown'].includes(iconType),
  }
}

export const generatePanels = (
  rawPanelInformation: USPBarQueryResult | undefined,
  isDesktop: boolean,
  theme: CombinedThemeInterface,
  useAlternateIcon: AlternateIcons,
): USPBarPanel[] => {
  const panels: USPBarPanel[] = []

  if (!rawPanelInformation) {
    return []
  }

  const textFieldToIconType = {
    rewardPointsText: 'rewards',
    countDownText: 'countDown',
    internationalDeliveryText: 'internationalDelivery',
    checkmarkText: 'checkmark',
    trustPilotText: 'trustpilot',
    calendarText: 'calender',
    emailText: 'email',
    deliveryText: 'delivery',
    callText: 'call',
    qualityText: 'quality',
    clickAndCollectText: 'clickAndCollect',
    storeText: 'store',
    returnsText: 'returns',
  }

  Object.keys(rawPanelInformation).forEach((dataField) => {
    if (/.*Text/.exec(dataField) && rawPanelInformation[dataField] !== '') {
      const iconType = textFieldToIconType[dataField]
      if (iconType) {
        const panelConfig = createPanelConfig(
          dataField,
          iconType,
          rawPanelInformation,
          theme,
          isDesktop,
        )
        pushToPanels(panels, panelConfig, theme)
      }
    }
  })

  panels.sort((a, b) => a.order - b.order)
  return panels
}

function pushToPanels(
  panels: USPBarPanel[],
  panel: USPBarPanel,
  theme: CombinedThemeInterface,
) {
  if (!panel.svg) {
    panel.svg = (
      <Clipboard
        fill={theme.widgets.uspBar.iconFill || theme.colors.palette.greys.dark}
      />
    )
    panel.isClipBoard = true
  }

  if (panel.link !== '') {
    panels.push(panel)
  } else {
    delete panel.link
    panels.push(panel)
  }
}

export const Panels = (props: { panels: USPBarPanel[] }) => {
  const { panels } = props
  return (
    <USPBarWrapper>
      <div
        style={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {panels.map((panel, index) => (
          <Panel
            data-testid="usp-desktop-panel"
            key={panel.key}
            panelCount={panels.length}
            index={index}
          >
            {panel.link ? (
              <PrefetchStyledHref href={panel.link}>
                <StyledSVGWrapper
                  isClipBoard={panel.isClipBoard}
                  useStrokeFill={panel.useStrokeFill}
                  data-testid={panel.testId}
                >
                  {panel.svg}
                </StyledSVGWrapper>
                {panel.child}
              </PrefetchStyledHref>
            ) : (
              <NoLinkContainer>
                <StyledSVGWrapper
                  isClipBoard={panel.isClipBoard}
                  useStrokeFill={panel.useStrokeFill}
                >
                  {panel.svg}
                </StyledSVGWrapper>
                {panel.child}
              </NoLinkContainer>
            )}
          </Panel>
        ))}
      </div>
      <IndicatorContainer>
        {Array(panels.length)
          .fill(null)
          .map((_, index) => (
            <IndicatorDot
              key={`indicator-${index}`}
              index={index}
              panelCount={panels.length}
            />
          ))}
      </IndicatorContainer>
    </USPBarWrapper>
  )
}
